<template>
<div class="items-grid-view w-full vx-row match-height">
    <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full" v-for="(item, index) in ballotList" :key="index">
        <vx-card title="Event Date" class="grid-view-item mb-base overflow-hidden" :customTitle="item.ballotDate | formatLongDate" subtitle="Opt In OR Opt Out of the ballot">
            <template slot="actions">
                <vs-chip color="success">{{ item.ballotStatus }}</vs-chip>
            </template>
            <template slot="no-body">
                <div class="item-img-container h-32 bg-white flex items-center justify-center cursor-pointer">
                    <div class="my-4">
                        <p class="item-description text-center truncate mb-2 font-bold">Vehicle</p>
                        <h6 class="truncate font-semibold text-center mb-2 hover:text-primary cursor-pointer">{{ item.model }} ({{ item.registrationPlate }})</h6>
                        <p class="item-description truncate">{{item.productName}} ({{item.passExpiryDate | formatLongDate}})</p>
                    </div>
                </div>
                <div v-if="item.ballotRequestStatus==='OptedIn'" class="item-img-container bg-white flex items-center justify-center cursor-pointer">
                    <div class="centerx">
                        <vs-chip color="success">Opted In</vs-chip>
                    </div>
                </div>
                <div v-if="item.ballotRequestStatus==='OptOut'" class="item-img-container bg-white flex items-center justify-center cursor-pointer">
                    <div class="centerx">
                        <vs-chip color="danger">Opted Out</vs-chip>
                    </div>
                </div>
                <div v-if="item.ballotRequestStatus==='Selected'" class="item-img-container bg-white flex items-center justify-center cursor-pointer">
                    <div class="centerx">
                        <vs-chip color="warning">Pending Approval</vs-chip>
                    </div>
                </div>
                <div v-if="item.ballotRequestStatus==='Success'" class="item-img-container bg-white flex items-center justify-center cursor-pointer">
                    <div class="centerx">
                        <vs-chip color="success">Successful</vs-chip>
                    </div>
                </div>
                <div v-if="item.ballotRequestStatus==='Failed'" class="item-img-container bg-white flex items-center justify-center cursor-pointer">
                    <div class="centerx">
                        <vs-chip color="danger">Unsuccessful</vs-chip>
                    </div>
                </div>
                <vs-divider />
                <div v-if="showActions(item.endDate)">
                    <div class="flex flex-wrap p-2 flex-grow items-center justify-center text-white" v-if="item.ballotRequestStatus !== 'Success' && item.ballotRequestStatus !== 'Unsuccess'">
                        <vs-button @click="optIn(item)" class="width100 mb-2" color="success" v-if="item.ballotRequestStatus !== 'OptedIn' && item.ballotRequestStatus !== 'Selected'">Opt In</vs-button>
                        <vs-button @click="optOut(item)" class="width100 mb-2" v-if="item.ballotRequestStatus !== 'OptOut'">Opt Out</vs-button>
                    </div>
                </div>
            </template>
        </vx-card>
    </div>
    <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full" v-if="ballotList.length <= 0 ">
        <vx-card title="Ballot" class="grid-view-item mb-base overflow-hidden">
            <template slot="no-body">
                <div class="item-img-container h-32 bg-white flex items-center justify-center cursor-pointer">
                    <div class="my-4">
                        <p class="item-description text-center truncate mb-2 font-bold">No Active Ballot</p>
                    </div>
                </div>
            </template>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot";

import moment from 'moment'

export default {
    data() {
        return {
            selectedPass: undefined,
            openAddWindow: false,
            ballotList: []
        }
    },
    components: {},
    async created() {
        await this.loadBallot()
    },
    methods: {
        showActions(endDate) {
            return new moment(endDate) >= new Date();
        },
        async optIn(item) {

            var data = {
                id: item.ballotRequestId,
                ballotId: item.ballotId,
                carId: item.carId
            };

            var result = await ballotData.optInBallot(data);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            await this.loadBallot();
            this.$vs.notify({
                title: "Success",
                text: "Record Updated",
                color: "success",
                position: "top-center"
            });
            return;
        },
        async optOut(item) {

            var data = {
                id: item.ballotRequestId,
                ballotId: item.ballotId,
                carId: item.carId
            };

            var result = await ballotData.optOutBallot(data);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            await this.loadBallot();
            this.$vs.notify({
                title: "Success",
                text: "Record Updated",
                color: "success",
                position: "top-center"
            });
            return;
        },
        async loadBallot() {
            this.ballotList = await ballotData.searchCurrentBallot();
        }
    }
}
</script>
